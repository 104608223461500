import React, { useState } from 'react'
import { DelBtn, EditBtn, Header, Modal, Theader } from '../../components'
import { useSnapshot } from 'valtio'
import { appStateProxy } from '../../core/stores'
import { adsHeader, slidesHeader } from '../../constant'
import { AddAds } from '../../sections'
import { deleteAds } from '../../core/stores/ads'

const Publicites = () => {
  const [modal,setModal]=useState<boolean>()
  const [editAds,setEditAds]=useState<{id:string}>()
  const {ads}=useSnapshot(appStateProxy)
  return (
    <section className='w-full'>
    <Header setDisplay={setModal} btn={true} />
    <table className="min-w-full divide-y divide-gray-300 bg-white">
    <Theader labels={adsHeader} />
    <tbody className="bg-white">
              {ads.map((advirt,idx) => (
                <tr key={idx} className="even:bg-gray-50">
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">{advirt.picture&&<img className='w-20 border ' src={advirt.picture} alt={'slide'}/>}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{advirt.contact}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><span className='flex  gap-4'>
                    <EditBtn id={advirt.id} setId={setEditAds} setDisplay={setModal}/>
                    <DelBtn  delteMethode={deleteAds} id={advirt.id}  />
                    </span></td>
                </tr>
              ))}
            </tbody>
  </table>
  {
    modal&&<Modal>
      <AddAds setDisplay={setModal} id={editAds} setId={setEditAds} />
    </Modal>
  }
  </section>
  )
}

export default Publicites