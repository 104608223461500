import React, { useEffect, useState } from 'react'
import { Input, SpinnerLoader } from '../../components'
import { IDelivery } from '../../core/models'
import { UplaodDeliveryAvatar, addDelivery, updateDelivery } from '../../core/stores/delivery'
import { uploadDataUrl } from '../../core/firebase/upload'
import { useSnapshot } from 'valtio'
import { appStateProxy } from '../../core/stores'

type IAddDelivery={
    setDisplay:any,
    id?:string
    setId:any
}
const AddDelivery = (props:IAddDelivery) => {
    const [file,setFile]=useState<any>('')
    const [delivery,setDelivry]=useState<Pick<IDelivery,"avatar"|"firstName"|"lastName"|"phoneNumber">>({avatar:"",firstName:"",lastName:"",phoneNumber:''})
    const [loader,setLoader]=useState<boolean>()
    const {deliveries}=useSnapshot(appStateProxy)
    const editDelivery=deliveries.find((del)=>del.id===props?.id)

    const changeDeleryHandler=(e:any)=>setDelivry((prev:any)=>({...prev,[e.target.name]:e.target.value}))
    const fileChangeHandler=(e:any)=>setFile(()=>e.target.files[0])
    const canceledHandler =()=>{
        setDelivry(()=>({avatar:"",firstName:"",lastName:"",phoneNumber:''}))
        setFile(()=>"")
        props.setId(()=>({id:''}))
        props.setDisplay(()=>false)
    }

    const submitFormHandler =async()=>{
        if(loader||!delivery.firstName||!delivery.lastName||!delivery.phoneNumber ||!file)return

        try {
            setLoader(()=>true)
            if(!props.id){
                const resId=await addDelivery(delivery)
                const imagePath=await UplaodDeliveryAvatar(resId,file)
                const data={...delivery,avatar:imagePath}
                await updateDelivery(resId,data)

            }
            if(props.id){
                let image=editDelivery?.avatar
                if(file!==editDelivery?.avatar){
                    image=await UplaodDeliveryAvatar(props.id,file)
                }
                const data={...delivery,avatar:image}
                await updateDelivery(props.id,data)
            }
            setLoader(()=>false)
            setDelivry(()=>({avatar:"",firstName:"",lastName:"",phoneNumber:''}))
            props.setDisplay(()=>false)
            
        } catch (error:any) {
            
        }
    }
    useEffect(()=>{
        setDelivry((prev:any)=>({...prev,avatar:"",firstName:props.id?editDelivery?.firstName:"",lastName:props.id?editDelivery?.lastName:"",phoneNumber:props.id?editDelivery?.phoneNumber:''})) 
        props.id&&setFile(()=>editDelivery?.avatar)
    }
    ,[])
  return (
    <section className='bg-white flex flex-col p-4 rounded-lupg gap-3 min-w-[450px]'>
    <span onClick={canceledHandler} className='text-right m-3 cursor-pointer select-none'>annuler</span>
    <div className='w-full flex justify-between items-center   '>
      <label >Nom:</label>
      <Input
      IType='text'
      name='firstName'
      value={delivery.firstName}
      onChangeValue={changeDeleryHandler}
      />
    </div>
    <div className='w-full flex items-center justify-between'>
      <label>Prénom:</label>
      <Input
      IType='text'
      name='lastName'
      value={delivery.lastName}
      onChangeValue={changeDeleryHandler}
      />
    </div>
    <div className='w-full flex items-center justify-between'>
      <label>Contact:</label>
      <Input
      IType='phone'
      name='phoneNumber'
      value={delivery.phoneNumber}
      onChangeValue={changeDeleryHandler}
      />
    </div>
 
     <div className='w-full border flex items-center justify-center py-4'>
        <input onChange={fileChangeHandler}   type='file'/>
    </div> 
    <div className='w-full flex justify-center items-center px-10 py-2'>
      <button onClick={submitFormHandler} className='w-full rounded-lg bg-adnocBg py-2 text-xl text-white' >
        {loader?<SpinnerLoader/>:'Valider'}
      </button>
    </div>
  </section>
  )
}

export default AddDelivery