// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
import { getMessaging } from "firebase/messaging"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBhRy7F_4eXMtNcVoG6rAhk12h6MizAHG8",
  authDomain: "fb-demo-bb833.firebaseapp.com",
  projectId: "fb-demo-bb833",
  storageBucket: "fb-demo-bb833.appspot.com",
  messagingSenderId: "611162438194",
  appId: "1:611162438194:web:43e9a656e75881004f9a2b",
  measurementId: "G-KSX213R0WM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
// const messaging = getMessaging(app);

export {
    app,
    // analytics,
    auth,
    firestore,
    storage,
    // messaging
}
