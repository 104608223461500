import React, { useState } from "react";
import { Input, SpinnerLoader } from "../components";
import { ISlide } from "../core/models";
import { addSlide, updateSlide, uploadCatFile } from "../core/stores/slide";
import { useSnapshot } from "valtio";
import { appStateProxy } from "../core/stores";

const AddSlide = ({ setDisplay,id,setId}: any) => {
  const [slide,setSlide] =useState<Pick<ISlide,"picture"|"designation"|"order">>({picture:'',designation:'',order:0})
  const [file,setFile]=useState<any>(null)
  const [loader,setLoader]=useState<boolean>()
  const {slides}=useSnapshot(appStateProxy)
  const newSlide=id&&slides.find((slide) => slide.id===id)

  const cancelledHandler = () => {
    setDisplay((prev: any) => !prev);
    setId((prev: any) => ({ ...prev, id: "" }));
  };
  const changeFileHandler=(e:any)=>setFile(()=>e.target.files[0])


  const changeHandler = (e:any) =>setSlide((prev:any)=>({...prev,[e.target.name]:e.target.value})) 

  // submit handlers
  const submitHandler = async () => {
    console.log(file);
    if ( !slide.designation || loader || !file) return;
    try {
      setLoader(() => true);
      if (!newSlide) {
        const res = await addSlide(slide);
        const image = await uploadCatFile(res, file);
        if (image) await updateSlide(res, { picture:image});
      }
      if (id) {
        if (file !== newSlide?.image) {
          const image = await uploadCatFile(id, file);
          if (image)
            await updateSlide(id, {
              picture: image,
              designation: slide.designation,
              order:slide.order
            });
        }
        if (file === newSlide?.picture)
          await updateSlide(id, {
            picture: file,
            designation: slide.designation,
            order:slide.order
          });
      }
      setDisplay((prev: any) => !prev);
      setId((prev: any) => ({ ...prev, id: "" }));
      setSlide(() => ({ picture: "", designation: "", order:0 }));
    } catch (e: any) {
      setLoader(() => false);
      console.log(e.message);
    }
  };
  return (
    <section className="bg-white flex flex-col p-4 rounded-lupg gap-3">
      <span
        onClick={cancelledHandler}
        className="text-right m-3 cursor-pointer select-none"
      >
        annuler
      </span>
      <div className="w-full flex items-center gap-2">
        <label>Designation:</label>
        <Input
          IType="text"
          name="designation"
          value={slide.designation}
          onChangeValue={changeHandler}
        />
      </div>
      <div className="w-full flex items-center gap-2">
        <label>Position:</label>
        <Input
          IType="number"
          name="order"
          value={slide.order}
          onChangeValue={changeHandler}
        />
      </div>
      <div className="w-full border flex items-center justify-center py-4">
        <input onChange={changeFileHandler} type="file" />
      </div>
      <div className="w-full flex justify-center items-center px-10 py-2">
        <button
          onClick={submitHandler}
          className="w-full rounded-lg bg-adnocBg py-2 text-xl text-white"
        >
          {loader? <SpinnerLoader /> : "Valider"}
        </button>
      </div>
    </section>
  );
};

export default AddSlide;
