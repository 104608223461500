import React from 'react'
import { StatCard } from '../components'
import { ShoppingBagIcon, ShoppingCartIcon, TruckIcon, UserIcon } from '@heroicons/react/24/outline'
import { appStateProxy } from '../core/stores'
import { useSnapshot } from 'valtio'

const StatisticCArds = () => {
  const {products,categories,orders,customers,deliveries}=useSnapshot(appStateProxy)
  return (
    <section className='w-full grid grid-cols-4 gap-2'>
        <StatCard
        icon={ShoppingBagIcon}
        name={'Produits/Categories'}
        total={categories.length?categories.length:"0"}
        active={products.length?products.length:'0'}
        />  
        <StatCard
        icon={ShoppingCartIcon}
        name={'Commandes'}
        total={orders.length?orders.length:"0"}
        />
        <StatCard
        icon={UserIcon}
        name={'Clients'}
        total={customers.length?customers.length:"0"}
        /> 
        <StatCard
        icon={TruckIcon}
        name={'Livreur'}
        total={deliveries.length?deliveries.length:"0"}
        />   
      </section>
  )
}

export default StatisticCArds