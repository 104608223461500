import React, { useEffect, useRef, useState } from 'react'
import { navLists } from '../constant'
import { Link } from 'react-router-dom'

const List =({label,path}:any)=>{
  const [focus,setFocus]=useState<boolean>()
  return (
    <Link onFocus={()=>setFocus(()=>true)} to={path} className='py-3 text-xl pl-2 hover:bg-primary hover:text-white'>
          <span>{}</span>
          <span>{label}</span>
    </Link>
  )
}

const Sidebar = () => {

  return (
    <div className='w-[15vw] border shadow-lg flex flex-col gap-4'>
      {navLists.map((list:any,idx:any)=>(
        <Link    key={idx} to={list.path} className='py-3 text-xl pl-2 focus:bg-adnocBg focus:text-white'>
          <span>{}</span>
          <span>{list.label}</span>
        </Link>
      ))}
    </div>
  )
}

export default Sidebar