import React from 'react'
import {Chart as ChartJS,registerables} from 'chart.js'
import {Doughnut} from 'react-chartjs-2'

type IDoughnut={
  labels:string[]
  dataSets:any[],
  options?:any
}
const DoughnutChart = (props:IDoughnut) => {
  ChartJS.register(...registerables);
  const data={
      labels:props.labels,
      datasets:props.dataSets,
      options:props.options
  }
  return (
    <div className=''>
    <Doughnut
    data={data}
    />
</div>
  )
}

export default DoughnutChart