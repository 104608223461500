import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import logo from '../assets/icons/adnoc.png'
import { listenAuthStatus } from '../core/firebase'
const AuthLayout = () => {
  const navigate=useNavigate()
  useEffect(()=>{
    listenAuthStatus((res)=>{
      console.log('State:',res)
      if(res && res.uid) navigate('/dashboard')
    })
  },[])
  return (
    <section className='w-screen h-screen flex flex-col bg-adnocBg justify-center items-center gap-8'>
      <section className=' flex flex-col items-center justify-center gap-4 text-white'>
        <img src={logo} alt='logo'/>
        <h1 className='text-white font-extrabold text-4xl '>Adnoc</h1>
      </section>
      <section className='flex items-center justify-center bg-white rounded-md min-w-[500px]'><Outlet/></section>
    </section>
  )
}

export default AuthLayout