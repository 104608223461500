import { fsAdd, fsDelete, fsGet, fsGetById, fsListen, fsUpdate } from "../firebase";
import { uploadFile } from "../firebase/upload";
import { ISlide } from "../models";

const colName = "slides";

const getSlides = async (): Promise<ISlide[]> => {
    const data: any[] = await fsGet(colName)
    return data;
}

const listenSlides = (cb: (slides: ISlide[]) => void): void => {
    fsListen(colName, cb)
}

const getSlide = (id: string): Promise<ISlide> => {
    const data: any = fsGetById(colName, id)
    return data
}

const addSlide = async (data: Partial<ISlide>): Promise<string> => {
    const { id } = await fsAdd(colName, data)
    return id
}

const updateSlide = async (id: string, data: Partial<ISlide>): Promise<void> => {
    await fsUpdate(colName, id, data)
}

const deleteSlide = async (id: string): Promise<void> => {
    await fsDelete(colName, id)
}
const uploadCatFile=(id:string,file:any)=>{
   return  uploadFile(`/images/slides/${id}`,file)
}

export { getSlides, getSlide, addSlide, updateSlide, deleteSlide, listenSlides,uploadCatFile } 
