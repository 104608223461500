import { fsAdd, fsDelete, fsGet, fsListen, fsUpdate } from "../firebase";
import { uploadFile } from "../firebase/upload";
import { IDelivery } from "../models";

const colName = "deliveries"

const getDeliveries = async (): Promise<IDelivery[]> => {
    const data: any[] = await fsGet(colName)
    return data;
}
const listenDeliveries=(cb:(deliveries:IDelivery[])=>void):void=>{
    fsListen(colName,cb)
}
const addDelivery = async (data: Partial<IDelivery>): Promise<string> => {
    const { id } = await fsAdd(colName, data)
    return id
}

const updateDelivery = async (id: string, data: Partial<IDelivery>): Promise<void> => {
    await fsUpdate(colName, id, data)
}

const deleteDelivery = async (id: string): Promise<void> => {
    await fsDelete(colName, id)
}
const UplaodDeliveryAvatar=(name:string,file:any)=>{
    return uploadFile(`delivery/${name}`,file)
}
export { getDeliveries, addDelivery, updateDelivery, deleteDelivery,UplaodDeliveryAvatar,listenDeliveries} 