import { fsAdd, fsDelete, fsGet, fsGetById, fsListen, fsUpdate } from "../firebase";
import { uploadFile } from "../firebase/upload";
import { IOrder } from "../models";

const colName = "orders";

const getOrders = async (): Promise<IOrder[]> => {
    const data: any[] = await fsGet(colName)
    return data;
}

const listenOrders = (cb: (Orders: IOrder[]) => void): void => {
    fsListen(colName, cb)
}

const getOrder = (id: string): Promise<IOrder> => {
    const data: any = fsGetById(colName, id)
    return data
}

const addOrder = async (data: Partial<IOrder>): Promise<string> => {
    const { id } = await fsAdd(colName, data)
    return id
}

const updateOrder = async (id: string, data: Partial<IOrder>): Promise<void> => {
    await fsUpdate(colName, id, data)
}

const deleteOrder = async (id: string): Promise<void> => {
    await fsDelete(colName, id)
}

export {getOrders,listenOrders,getOrder,addOrder,updateOrder,deleteOrder}