import { fsAdd, fsDelete, fsGet, fsGetById, fsListen, fsUpdate } from "../firebase";
import { uploadFile } from "../firebase/upload";
import { IAds } from "../models";

const colName = "ads";

const getAdses = async (): Promise<IAds[]> => {
    const data: any[] = await fsGet(colName)
    return data;
}

const listenAdses = (cb: (Adses: IAds[]) => void): void => {
    fsListen(colName, cb)
}

const getAds = (id: string): Promise<IAds> => {
    const data: any = fsGetById(colName, id)
    return data
}

const addAds = async (data: Partial<IAds>): Promise<string> => {
    const { id } = await fsAdd(colName, data)
    return id
}

const updateAds = async (id: string, data: Partial<IAds>): Promise<void> => {
    await fsUpdate(colName, id, data)
}

const deleteAds = async (id: string): Promise<void> => {
    await fsDelete(colName, id)
}
const uploadCatFile=(id:string,file:any)=>{
   return  uploadFile(`/images/adses/${id}`,file)
}

export { getAdses, getAds, addAds, updateAds, deleteAds, listenAdses,uploadCatFile } 
