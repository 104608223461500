import React, { useState } from 'react'
import { AddProduct, ProductHeader, ProductList } from '../../sections/productsSections'
import { Input, Modal, SpinnerLoader } from '../../components'

const Produits = () => {
  const [modal,setModal]=useState<boolean>()
  const [editProd ,setEditProd]=useState<{id:string}>()
  return (
    <section className='w-full h-full flex flex-col gap-4'>
      <ProductHeader setDisplay={setModal}/>
      <ProductList setId={setEditProd} display={setModal}/>
      {modal&&
      <Modal>
        <AddProduct id={editProd?.id} setId={setEditProd} setDisplay={setModal} />
      </Modal>}
    </section>
  )
}

export default Produits