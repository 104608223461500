import { fsAdd, fsDelete, fsGet, fsGetById, fsListen, fsUpdate } from "../firebase";
import { uploadFile } from "../firebase/upload";
import { ICategory } from "../models";

const colName = "categories";

const getCategories = async (): Promise<ICategory[]> => {
    const data: any[] = await fsGet(colName)
    return data;
}

const listenCategories = (cb: (Categories: ICategory[]) => void): void => {
    fsListen(colName, cb)
}

const getCategory = (id: string): Promise<ICategory> => {
    const data: any = fsGetById(colName, id)
    return data
}

const addCategory = async (data: Partial<ICategory>): Promise<string> => {
    const { id } = await fsAdd(colName, data)
    return id
}

const updateCategory = async (id: string, data: Partial<ICategory>): Promise<void> => {
    await fsUpdate(colName, id, data)
}

const deleteCategory = async (id: string): Promise<void> => {
    await fsDelete(colName, id)
}
const uploadCatFile=(id:string,file:any)=>{
   return  uploadFile(`/images/categories/${id}`,file)
}

export { getCategories, getCategory, addCategory, updateCategory, deleteCategory, listenCategories,uploadCatFile } 
