import React from 'react'
type ITheader={
    labels:string[]
}

const Theader = (props:ITheader) => {
  return (
    <thead>
    <tr>
        {props.labels.map((list:any,idx)=>(
             <th key={idx} scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
             {list}
           </th>
        ))}
    </tr>
  </thead>
  )
}

export default Theader