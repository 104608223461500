import React from 'react'
import { Theader } from '../components'

type ICommande={

}
const CommandeSection = (props:ICommande) => {
  return (
    <section className='w-full flex flex-col bg-white space-y-4 '>
      <h3 className='p-2 font-bold text-xl'>Commandes</h3>
      <table className="min-w-full divide-y divide-gray-300 overflow-y-auto  max-h-96  ">
        <Theader labels={['Client',"livreur","Status"]} />
        <tbody className="bg-white">
                {[1,2,3,4,5].map((cat,idx) => (
                  <tr key={idx} className="even:bg-gray-50">
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{'paul'}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{'jean'}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm font-bold text-green-400">{'Livrer'}</td>
                  </tr>
                ))}
              </tbody>
      </table>
    </section>
  )
}

export default CommandeSection