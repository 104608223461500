
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Input, Loader, SpinnerLoader } from '../../components'
import { loginWithEmail } from '../../core/firebase'

const Login = () => {
  const [credentials,setCredentials] =useState<{email:string,password:string}>({email:'',password:''})
  const [loader,setLoader]=useState<boolean>()
  const navigate=useNavigate()

  // Input change handler
  const changeHandler =(e:any)=>setCredentials((prev:any)=>({...prev,[e.target.name]:e.target.value}));

  // Submit div handler
  const submitHandler =async(e:any)=>{
    if(!credentials.email || !credentials.password || loader)return
    try {
      setLoader(()=>true)
      await loginWithEmail(credentials.email,credentials.password)
    } catch (error:any) {
      setLoader(()=>false)
      console.log(error)
    }
  }

    return(
      <>
    <div className='flex min-h-full  flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
      <div className="sm:mx-auto sm:w-full sm:max-w-sm flex flex-col">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-blue-600">
          Connexion
        </h2>
        {false&&<span className=' text-center text-danger'>Email or Password incorect</span>}
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="space-y-6 " >
          <div className='flex flex-col gap-2'>
            <label htmlFor="email" className="block text-md font-medium leading-6 text-blue-600">
            Addresse Email 
            </label>
              <Input
              IType='email'
              name='email'
              value={credentials.email}
              onChangeValue={changeHandler}
              />
          </div>

          <div className='flex flex-col gap-2'>
              <label htmlFor="password" className="block text-md font-medium leading-6 text-blue-600">
                Mot de passe
              </label>
              <Input
              IType='password'
              name='password'
              onChangeValue={changeHandler}
              value={credentials.password}
              />
          </div>

          <div>
          <button
            className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-md font-semibold leading-6 text-white shadow-sm "
            onClick={submitHandler}
            >
            {loader?<SpinnerLoader/>:'Se Connecter'}
          </button>
          </div>
          <div className="text-sm">
                <Link className='text-blue-600' to={'/verifyemail'}>Mot de passe oublier?</Link>
              </div>
        </div>
      </div>
    </div>
    </>  
    )
    }
export default Login