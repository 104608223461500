export const navLists=[
    {
        label:'Dashboard',
        path:'/dashboard',
        icon:''
    },
    {
        label:'Categories',
        path:'/dashboard/categories',
        icon:''
    },
    {
        label:'Produits',
        path:'/dashboard/produits',
        icon:''
    },
    {
        label:'Commandes',
        path:'/dashboard/commandes',
        icon:''
    },
    {
        label:'Clients',
        path:'/dashboard/clients',
        icon:''
    },
    {
        label:'Livreurs',
        path:'/dashboard/livreurs',
        icon:''
    },
    {
        label:'Slides',
        path:'/dashboard/slides',
        icon:''
    },
    {
        label:'Publicités',
        path:'/dashboard/publicites',
        icon:''
    },
    {
        label:'Paramètres',
        path:'/dashboard/parametres',
        icon:''
    }
]

export const prodHeader=[
    'Designation',
    'Prix',
    'Quantiter',
    'Image',
    'Status'
]
export const commandeHeader=['Date','Client','Produit','Livreur']
export const  categoriesHeader=['Designation','Description', 'Image','Status']
export const userHeader =['Nom','Prénom','Contact','Avatar',"Status"]
export const slidesHeader =['Image','Designation','Ordre','Status']
export const adsHeader=['Image','Contact','Order','Status']