import React, { useState } from 'react'
import {  prodHeader } from '../../constant'
import { Link } from 'react-router-dom'
import { DelBtn, EditBtn, Theader } from '../../components'
import { useSnapshot } from 'valtio'
import { appStateProxy, deleteProduct } from '../../core/stores'

type IProduct={
  display:any
  setId:any
}
const ProductList = (props:IProduct) => {
  const [viewId,setViewId] =useState<{id:string}>()
  const {products}=useSnapshot(appStateProxy)

  return (
    <table className="min-w-full divide-y divide-gray-300 bg-white py-2">
      <Theader labels={prodHeader} />
      <tbody className="bg-white">
                {products.map((prod,idx) => (
                  <tr key={idx} className="even:bg-gray-50">
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{prod.name}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{prod.unitPrice}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{prod.quantity}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{prod.images&&<img className='w-10 rounded-full border' src={prod.images} alt={prod.name}/>}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <span className='flex gap-4'>
                      <EditBtn id={prod.id} setDisplay={props.display}  setId={props.setId} />
                      <DelBtn delteMethode={deleteProduct} id={prod.id} />
                    </span></td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><Link onClick={()=>localStorage.setItem('adnocProdId',prod.id)} className='text-primary underline' to={'/dashboard/produits/produit-detail'}>View</Link></td>
                  </tr>
                ))}
              </tbody>
    </table>
  )
}

export default ProductList