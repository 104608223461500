import React, { useState } from 'react'
import { DelBtn, EditBtn, Header, Modal, Theader } from '../../components'
import { userHeader } from '../../constant'
import { AddDelivery } from '../../sections/delivery'
import { useSnapshot } from 'valtio'
import { appStateProxy } from '../../core/stores'
import { deleteDelivery } from '../../core/stores/delivery'

const Livreurs = () => {
  const [editDelivery,setEditDelivery]=useState<{id:string}>()
  const [modal,setModal] =useState<boolean>()
  const {deliveries}=useSnapshot(appStateProxy)
  return (
    <section className='w-full h-full flex flex-col gap-4'>
    <Header setDisplay={setModal}   btn={true} />
    <table className="min-w-full divide-y divide-gray-300 bg-white">
    <Theader labels={userHeader} />
    <tbody className="bg-white">
              {deliveries.map((deliv,idx) => (
                <tr key={idx} className="even:bg-gray-50">
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{deliv.firstName}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{deliv.lastName}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{deliv.phoneNumber}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">{deliv.avatar&&<img className='w-10 border rounded-full' src={deliv.avatar} alt={deliv.lastName}/>}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><span className='flex  gap-4'>
                    <EditBtn id={deliv.id} setId={setEditDelivery} setDisplay={setModal}/>
                    <DelBtn  delteMethode={deleteDelivery} id={deliv.id}  />
                    </span></td>
                </tr>
              ))}
            </tbody>
  </table>
  {modal&&
  <Modal>
    <AddDelivery id={editDelivery?.id}  setId={setEditDelivery} setDisplay={setModal}/>
  </Modal>
  }
  </section>
  )
}

export default Livreurs