import React from 'react'

type IStatisticCard={
    name:any,
    icon:any,
    total:number|string,
    active?:number|string,
}

const StatCard = (props:IStatisticCard) => {
  return (
    <section className='w-full flex px-5 py-8 bg-white rounded-md shadow-md'>

    <section className='w-full flex justify-between items-center'>
    <div className='space-y-2'>
     <span className='font-bold text-secondary'>{props.name}</span>
     {props.active?<h3 className='text-4xl' >{props.active}/{props.total}</h3>:<h3 className='text-4xl' >{props.total}</h3>}
    </div>
     <div><span className='flex justify-center items-center p-2 rounded-full border-[3px] text-secondary border-secondary '>{<props.icon className='w-10 h-10' />}</span></div>
    </section>

    <section></section>
   </section>
  )
}

export default StatCard