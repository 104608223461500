
import { ref, uploadBytes, uploadString, getDownloadURL } from "firebase/storage";
import { storage } from ".";


export const uploadFile = async (path: string, file: File) => {
    const storageRef = ref(storage, path);
    // 'file' comes from the Blob or File API
    const snapshot = await uploadBytes(storageRef, file)
    console.log(snapshot.ref.fullPath)
    return await getDownloadURL(snapshot.ref)
}

export const uploadDataUrl = async (path: string, data: string) => {
    const storageRef = ref(storage, path);
    const snapshot = await uploadString(storageRef, data, "data_url")
    return await getDownloadURL(snapshot.ref)
} 