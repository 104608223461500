import React from 'react'
import {Chart as ChartJS,registerables} from 'chart.js'
import { Bar } from 'react-chartjs-2'

type IDoughnut={
    labels:string[]
    dataSets:any[],
    options?:any
}
const LinearChart = (props:IDoughnut) => {
    ChartJS.register(...registerables);
    const data={
        labels:props.labels,
        datasets:props.dataSets,
        options:props.options
    }
  return (
    <div className=''>
    <Bar
    data={data}
    />
</div>
  )
}

export default LinearChart