import { proxy } from "valtio"
import { 
    listenAdmin,
    listenAdmins,
    listenCategories,
    listenOrders,
    listenProducts,
    listenUser 
} from ".";
import { IState } from "../models";
import { listenDeliveries } from "./delivery";
import { listenCustomers } from "./customer";
import { listenShops } from "./shop";
import { listenSlides } from "./slide";
import { listenAdses } from "./ads";


const appStateProxy = proxy<IState>({
    // properties
    profile: null,
    categories: [],
    products: [],
    admins:[],
    ads:[],
    customers:[],
    deliveries:[],
    orders:[],
    shops:[],
    slides:[],

    // methods
    init:()=>{
        appStateProxy.setCategories();
        appStateProxy.setProducts();
        appStateProxy.setAdmins();
        appStateProxy.setAds();
        appStateProxy.setCustomers();
        appStateProxy.setDeliveries();
        appStateProxy.setOrders();
        appStateProxy.setShops();
        appStateProxy.setSlides()

    },
    setProfile: (uid) => {
        listenAdmin(uid, (admin) => { appStateProxy.profile= admin})
    },
    
    setCategories: () => {
        listenCategories((categories) => appStateProxy.categories = categories)
    },
 
    setProducts: () => { 
        listenProducts((products) => appStateProxy.products = products)
     },
     setAdmins: () => {
        listenAdmins((admins) => appStateProxy.admins = admins)
     },
     setAds: () => {
      listenAdses((adses)=> appStateProxy.ads=adses)
     },
     setCustomers: () => {
        listenCustomers((customers)=>appStateProxy.customers=customers)
     },
     setDeliveries: () => {
        listenDeliveries((deliveries)=>appStateProxy.deliveries=deliveries)
     },
     setOrders: () => {
        listenOrders((orders)=>appStateProxy.orders=orders)
     },
     setShops: () => {
        listenShops((shops)=>appStateProxy.shops=shops)
     },
     setSlides: () => {
      listenSlides((slides)=>appStateProxy.slides=slides)
     }
})

export { appStateProxy }