import { fsAdd, fsDelete, fsGet, fsListen, fsUpdate } from "../firebase";
import { ICustomer } from "../models";

const colName = "customers"

const getCustomers = async (): Promise<ICustomer[]> => {
    const data: any[] = await fsGet(colName)
    return data;
}
const listenCustomers =(cb:(customers:ICustomer[])=>void):void=>{
    fsListen(colName,cb)
}
const addCustomer = async (data: Partial<ICustomer>): Promise<string> => {
    const { id } = await fsAdd(colName, data)
    return id
}

const updateCustomer = async (id: string, data: Partial<ICustomer>): Promise<void> => {
    await fsUpdate(colName, id, data)
}

const deleteCustomer = async (id: string): Promise<void> => {
    await fsDelete(colName, id)
}

export { getCustomers, addCustomer, updateCustomer, deleteCustomer,listenCustomers} 
