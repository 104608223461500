import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, serverTimestamp, setDoc, updateDoc } from "firebase/firestore"
import { firestore } from "./init"

// Level 1
const fsAdd = (col: string, data: any) => {
    const collectionRef = collection(firestore, col)
    return addDoc(collectionRef, { ...data, createdAt: serverTimestamp() })
}

const fsSet = (col: string, id: string, data: any) => {
    const docRef = doc(firestore, col, id)
    return setDoc(docRef, { ...data, createdAt: serverTimestamp() })
}

const fsGet = async (col: string) => {
    const collectionRef = collection(firestore, col)
    const res = await getDocs(collectionRef)
    return res.docs.map(doc => ({ id: doc.id, ...doc.data() }))
}

const fsGetById = async (col: string, id: string) => {
    const docRef = doc(firestore, col, id)
    const res = await getDoc(docRef)
    return res.exists() ? { id: res.id, ...res.data() } : null
}

const fsListen = (col: string, cb: any): void => {
    const collectionRef = collection(firestore, col)
    onSnapshot(collectionRef, (res) => {
        cb(res.docs.map(doc => ({ id: doc.id, ...doc.data() })))
    })
}

const fsListenById = (col: string, id: string, cb: any): void => {
    const docRef = doc(firestore, col, id)
    onSnapshot(docRef, (res) => {
        cb(res.exists() ? { id: res.id, ...res.data() } : null)
    })
}

const fsUpdate = async (col: string, id: string, data: any) => {
    const docRef = doc(firestore, col, id)
    return updateDoc(docRef, { ...data, updatedAt: serverTimestamp() })
}

const fsDelete = async (col: string, id: string) => {
    const docRef = doc(firestore, col, id)
    return deleteDoc(docRef)
}

// Level2
const fsListenSubDocs = (parentCol: string, parentId: string, col: string, cb: any): void => {
    const collectionRef = collection(firestore, parentCol, parentId, col)
    onSnapshot(collectionRef, (res) => {
        cb(res.docs.map(doc => ({ id: doc.id, ...doc.data() })))
    })
}

const fsListenSubDoc = (parentCol: string, parentId: string, col: string, id: string, cb: any): void => {
    const docRef = doc(firestore, parentCol, parentId, col, id)
    onSnapshot(docRef, (res) => {
        cb(res.exists() ? { id: res.id, ...res.data() } : null)
    })
}

const fsAddSubDoc = (parentCol: string, parentId: string, col: string, data: any) => {
    const collectionRef = collection(firestore, parentCol, parentId, col)
    return addDoc(collectionRef, { ...data, createdAt: serverTimestamp() })
}

const fsGetSubDocs = async (parentCol: string, parentId: string, col: string,) => {
    const collectionRef = collection(firestore, parentCol, parentId, col)
    const res = await getDocs(collectionRef)
    return res.docs.map(doc => ({ id: doc.id, ...doc.data() }))
}

const fsSetSubDocs = (parentCol: string, parentId: string, col: string, id: string, data: any) => {
    const docRef = doc(firestore, parentCol, parentId, col, id)
    return setDoc(docRef, { ...data, createdAt: serverTimestamp() })
}

const fsUpdateSubDoc = async (parentCol: string, parentId: string, col: string, id: string, data: any) => {
    const docRef = doc(firestore, parentCol, parentId, col, id)
    return setDoc(docRef, { ...data, updatedAt: serverTimestamp() })
}

const fsDeleteSubDoc = async (parentCol: string, parentId: string, col: string, id: string) => {
    const docRef = doc(firestore, parentCol, parentId, col, id)
    return deleteDoc(docRef)
}

// Level3
const fsListenSubDocs2 = (ancestorCol: string, ancestorId: string,  parentCol: string, parentId: string, col: string, cb: any): void => {
    const collectionRef = collection(firestore, ancestorCol, ancestorId, parentCol, parentId, col)
    onSnapshot(collectionRef, (res) => {
        cb(res.docs.map(doc => ({ id: doc.id, ...doc.data() })))
    })
}

const fsListenSubDoc2 = (ancestorCol: string, ancestorId: string,  parentCol: string, parentId: string, col: string, id: string, cb: any): void => {
    const docRef = doc(firestore, ancestorCol, ancestorId, parentCol, parentId, col, id)
    onSnapshot(docRef, (res) => {
        cb(res.exists() ? { id: res.id, ...res.data() } : null)
    })
}

const fsAddSubDoc2 = (ancestorCol: string, ancestorId: string,  parentCol: string, parentId: string, col: string, data: any) => {
    const collectionRef = collection(firestore, ancestorCol, ancestorId, parentCol, parentId, col)
    return addDoc(collectionRef, { ...data, createdAt: serverTimestamp() })
}

const fsGetSubDocs2 = async (ancestorCol: string, ancestorId: string,  parentCol: string, parentId: string, col: string,) => {
    const collectionRef = collection(firestore, ancestorCol, ancestorId, parentCol, parentId, col)
    const res = await getDocs(collectionRef)
    return res.docs.map(doc => ({ id: doc.id, ...doc.data() }))
}

const fsSetSubDocs2 = (ancestorCol: string, ancestorId: string,  parentCol: string, parentId: string, col: string, id: string, data: any) => {
    const docRef = doc(firestore, ancestorCol, ancestorId, parentCol, parentId, col, id)
    return setDoc(docRef, { ...data, createdAt: serverTimestamp() })
}

const fsUpdateSubDoc2 = async (ancestorCol: string, ancestorId: string,  parentCol: string, parentId: string, col: string, id: string, data: any) => {
    const docRef = doc(firestore, ancestorCol, ancestorId, parentCol, parentId, col, id)
    return setDoc(docRef, { ...data, updatedAt: serverTimestamp() })
}

const fsDeleteSubDoc2 = async (ancestorCol: string, ancestorId: string,  parentCol: string, parentId: string, col: string, id: string) => {
    const docRef = doc(firestore, ancestorCol, ancestorId, parentCol, parentId, col, id)
    return deleteDoc(docRef)
}


export {
    fsAdd,
    fsSet,
    fsGet,
    fsUpdate,
    fsDelete,
    fsGetById,
    fsListen,
    fsListenById,
    // 
    fsAddSubDoc,
    fsGetSubDocs,
    fsSetSubDocs,
    fsUpdateSubDoc,
    fsDeleteSubDoc,
    fsListenSubDocs,
    fsListenSubDoc,
    //
    fsAddSubDoc2,
    fsGetSubDocs2,
    fsSetSubDocs2,
    fsUpdateSubDoc2,
    fsDeleteSubDoc2,
    fsListenSubDocs2,
    fsListenSubDoc2
}
