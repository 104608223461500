import React, { useState } from 'react'
import { DelBtn, EditBtn, Header, Modal, Theader } from '../../components'
import { Link } from 'react-router-dom'
import { categoriesHeader, prodHeader } from '../../constant'
import { AddCategory } from '../../sections/categoriesSections'
import { useSnapshot } from 'valtio'
import { appStateProxy, deleteCategory } from '../../core/stores'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'



const Categories = () => {
    const [modal,setModal]=useState<boolean>()
    const { categories } = useSnapshot(appStateProxy)
    const [editCat,setEditCat] = useState<{id:string}>()
  return (
    <section className='w-full h-full flex flex-col gap-4'>
      <Header setDisplay={setModal} btn={true}/>
      <table className="min-w-full divide-y divide-gray-300 bg-white">
      <Theader labels={categoriesHeader} />
      <tbody className="bg-white">
                {categories.map((cat,idx) => (
                  <tr key={idx} className="even:bg-gray-50">
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{cat.name}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{cat.description}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">{cat.image&&<img className='w-14 border rounded-full' src={cat.image} alt={cat.name}/>}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><span className='flex  gap-4'>
                      <EditBtn id={cat.id} setId={setEditCat} setDisplay={setModal}/>
                      <DelBtn  delteMethode={deleteCategory} id={cat.id}  />
                      </span></td>
                  </tr>
                ))}
              </tbody>
    </table>
    {modal&&<Modal>
        <AddCategory id={editCat?.id} setDisplay={setModal} setId={setEditCat}/>
    </Modal>}
    </section>
  )
}

export default Categories