import React, { useEffect, useState } from 'react'
import { Input, SpinnerLoader } from '../../components'
import { ICategory } from '../../core/models'
import { addCategory, appStateProxy, updateCategory, uploadCatFile } from '../../core/stores'
import { useNavigate } from 'react-router-dom'
import { useSnapshot } from 'valtio'

const AddCategory = ({setDisplay,id,setId}:any) => {
  const [Category,setCategory] =useState<Pick<ICategory,"name"|"description">>({name:'',description:''})
  const [loader,setLoader]=useState<boolean>()
  const [file,setFile]=useState<any>(null)

  // change handling
  const changeHandler=(e:any)=>setCategory((prev:any)=>({...prev,[e.target.name]:e.target.value}))
  const changeFileHandler=(e:any)=>setFile(()=>e.target.files[0])
  const {categories}=useSnapshot(appStateProxy)
  const cat=id&&categories.find((cat)=>cat.id===id)

  const cancelledHandler=()=>{
    setDisplay((prev:any)=>!prev)
    setId((prev:any)=>({...prev,id:''}))    
  }

  // submit handlers
  const submitHandler= async ()=>{
    console.log(file)
    if(!Category.name || !Category.description || loader|| !file)return
    try {
      setLoader(()=>true)
      if(!cat){
        const res=await addCategory(Category)
        const image=await uploadCatFile(res,file)
        if(image)await updateCategory(res,{image})
      }
    if(id){
      if(file!==cat?.image){
        const image=await uploadCatFile(id,file)
        if(image)await updateCategory(id,{image,description:Category.description,name:Category.name})
      }
    if(file===cat?.image)await updateCategory(id,{image:file,description:Category.description,name:Category.name})
    }
      setDisplay((prev:any)=>!prev)
      setId((prev:any)=>({...prev,id:''}))    
      setCategory(()=>({name:'',description:''}))
     } catch (e:any) {
      setLoader(()=>false)
      console.log(e.message)
     }
  }
  useEffect(()=>{
    setCategory((prev:any)=>({...prev,name:cat?.name?cat?.name:'',description:cat?.description?cat?.description:''}))
    id&&setFile(()=>cat?.image)
  },[])
  return (
    <section className='bg-white flex flex-col p-4 rounded-lupg gap-3'>
    <span onClick={cancelledHandler} className='text-right m-3 cursor-pointer select-none'>annuler</span>
    <div className='w-full flex items-center gap-2  '>
      <label >Designation:</label>
      <Input
      IType='text'
      name='name'
      value={Category.name}
      onChangeValue={changeHandler}
      />
    </div>
    <div className='w-full flex items-center gap-2'>
      <label>Description:</label>
      <Input
      IType='text'
      name='description'
      value={Category.description}
      onChangeValue={changeHandler}
      />
    </div>
 
     <div className='w-full border flex items-center justify-center py-4'>
        <input onChange={changeFileHandler}   type='file'/>
    </div> 
    <div className='w-full flex justify-center items-center px-10 py-2'>
      <button onClick={submitHandler} className='w-full rounded-lg bg-adnocBg py-2 text-xl text-white' >
        {loader?<SpinnerLoader/>:'Valider'}
      </button>
    </div>
  </section>
  )
}

export default AddCategory