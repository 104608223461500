import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Login, Emailverify, Otp, ForgotPassword } from './pages/auth'
import { AuthLayout, DashboardLayout } from './layouts'
import { Categories, Clients, Commandes, Dashboard, Livreurs, Parametres, Produits, Publicites, Slides } from './pages/dashboard'
import { ProductDetail } from './sections/productsSections'

const App = () => {
  return (
    <Routes>
        {/* Authentification routing start*/}
        <Route path='/' element={<AuthLayout/>}>
            <Route path='/' element={<Login/>}/>
            <Route path='/verifyemail' element={<Emailverify/>}/>
            <Route path='/verifyotp' element={<Otp/>}/>
            <Route path='/forgotpassword' element={<ForgotPassword/>}/>
        </Route>
        {/* Authentification routing end */}

        {/* Dashboard routing start */}
        <Route path='/dashboard' element={<DashboardLayout/>}>
          <Route path='/dashboard/' element={<Dashboard/>}/>
          <Route path='categories' element={<Categories/>}/>
          <Route path='produits' element={<Produits/>} />
          <Route path='produits/produit-detail' element={<ProductDetail/>}/>
          <Route path='commandes' element={<Commandes/>} />
          <Route path='livreurs' element={<Livreurs/>}/>
          <Route path='clients' element={<Clients/>} />
          <Route path='parametres' element={<Parametres/>} />
          <Route path='publicites' element={<Publicites/>} />
          <Route path='slides' element={<Slides/>} />
        </Route>
        {/* Dashboard routing end */}
    </Routes>
  )
}

export default App