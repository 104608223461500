import React, { useState } from 'react'
import { DelBtn, EditBtn, Header, Modal, Theader } from '../../components'
import { slidesHeader } from '../../constant'
import AddSlide from '../../sections/AddSlide'
import { useSnapshot } from 'valtio'
import { appStateProxy } from '../../core/stores'
import { deleteSlide } from '../../core/stores/slide'

const Slides = ({setDisplay,id,setId}:any) => {
  const [modal,setModal]=useState<boolean>()
  const [editSlide,setEditSlide]=useState<{id:string}>()
  const {slides}=useSnapshot(appStateProxy)



  return (
    <section className='w-full'>
      <Header setDisplay={setModal} btn={true} />
      <table className="min-w-full divide-y divide-gray-300 bg-white">
      <Theader labels={slidesHeader} />
      <tbody className="bg-white">
                {slides.map((slide,idx) => (
                  <tr key={idx} className="even:bg-gray-50">
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">{slide.picture&&<img className='w-20 border ' src={slide.picture} alt={'slide'}/>}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{slide.designation}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{slide.order}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><span className='flex  gap-4'>
                      <EditBtn id={slide.id} setId={setEditSlide} setDisplay={setModal}/>
                      <DelBtn  delteMethode={deleteSlide} id={slide.id}  />
                      </span></td>
                  </tr>
                ))}
              </tbody>
    </table>
    {
      modal&&<Modal>
        <AddSlide setDisplay={setModal} id={ editSlide?.id} setId={setEditSlide} />
      </Modal>
    }
    </section>
  )
}

export default Slides