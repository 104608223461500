import { TrashIcon } from '@heroicons/react/24/outline'
import React, { useState } from 'react'
import Modal from './Modal'
import SpinnerLoader from './SpinnerLoader'
type IDelBtn={
    id:string,
    delteMethode:any
}
const DelBtn = (props:IDelBtn) => {
    const [confirmation,setConfirmation] = useState<boolean>()
    const [loader,setLoader]=useState<boolean>()
    const deleteHandler =async()=>{
      if(loader)return
        try {
          setLoader(()=>true)
            await props.delteMethode(props.id)
            setConfirmation(()=>false)
            setLoader(()=>false)
        } catch (error:any) {
            setLoader(()=>false)
        }
    }
  return (
    <>
    {confirmation&&
    <Modal>
      <div className='flex flex-col gap-4 rounded-md p-5 bg-white select-none'>
        <p className='pt-4 pb-2'>Voulez-vous supprimer cet element?</p>
        <div className='w-full flex justify-center items-center gap-4'>
          <button onClick={()=>setConfirmation(()=>false)} className='w-full border-2 p-2 border-black text-black rounded-md font-bold '>Annuler</button>
          <button onClick={deleteHandler} className='w-full border-2 border-danger text-danger font-bold rounded-md p-2'>{loader?<SpinnerLoader/>:'Confirmer'}</button>
        </div>
      </div>
    </Modal>}
    <span  onClick={()=>setConfirmation(()=>true)} className='w-6 cursor-pointer text-danger '><TrashIcon/></span>
    </>
  )
}

export default DelBtn