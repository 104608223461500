import React from 'react'
import {IonIcon} from '@ionic/react'

const ProductHeader = ({setDisplay}:any) => {
  return (
    <section className='w-full bg-white py-4 px-2 shadow-lg flex items-center justify-between'>
      <div className='flex'>
        <input className='border  py-2 px-3 md:min-w-[500px] outline-none rounded-l-full' type="text" />
        <label className='border flex items-center px-2 bg-gray-400 font-light'>Rechercher</label>
      </div>
      <div>
        <button onClick={()=>setDisplay((prev:any)=>!prev)} className='bg-adnocBg text-white px-4 py-2 rounded-full'>Ajouter</button>
      </div>
    </section>
  )
}

export default ProductHeader