import { fsAdd, fsDelete, fsGet, fsListen, fsListenById, fsUpdate } from "../firebase";
import { IAdmin } from "../models";

const colName = "admins"

const getAdmins = async (): Promise<IAdmin[]> => {
    const data: any[] = await fsGet(colName)
    return data;
}

const listenAdmins = (cb: (admins: IAdmin[]) => void): void => {
    fsListen(colName, cb)
}

const listenAdmin = (id: string, cb: (admin: IAdmin) => void): void => {
    fsListenById(colName, id, cb)
}


const addAdmin = async (data: Partial<IAdmin>): Promise<string> => {
    const { id } = await fsAdd(colName, data)
    return id
}

const updateAdmin = async (id: string, data: Partial<IAdmin>): Promise<void> => {
    await fsUpdate(colName, id, data)
}

const deleteAdmin = async (id: string): Promise<void> => {
    await fsDelete(colName, id)
}

export { getAdmins, addAdmin, updateAdmin, deleteAdmin, listenAdmin, listenAdmins } 