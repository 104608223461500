import { HTMLInputTypeAttribute } from "react";
import styles from "./input.module.scss";

/* eslint-disable-next-line */
type InputProps = {
  label?: string,
  name: string;
  onChangeValue:any;
  IType: HTMLInputTypeAttribute;
  placeholder?: string;
  value?:any;
  className?: string | undefined;
};

export function Input(props: InputProps) {
  // input update controler
  return (
    <div className={`w-full flex flex-col gap-1.5`}>
      {props.label&&<label className={"text-black text-[14px]"}>
        {props.label}
      </label>}
      <input
        value={props.value}
        name={props.name}
        type={props.IType}
        onChange={props.onChangeValue}
        placeholder={props.placeholder}
        className={` ${props.className} border w-full h-10 text-black text-[14px] px-4 py-2 rounded-md outline-none`}
      />
    </div>
  );
}

export default Input;

