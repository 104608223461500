import React from 'react'
import { DoughnutChart, LinearChart, StatCard } from '../../components'
import {UserIcon} from '@heroicons/react/24/outline'
import { CommandeSection, StatisticCArds } from '../../sections'
import { useSnapshot } from 'valtio'
import { appStateProxy } from '../../core/stores'

const Dashboard = () => {
  const {products,categories}=useSnapshot(appStateProxy)
  return (
    <section className='w-full h-full flex flex-col gap-8'>
      {/* Statistic card */}
      <StatisticCArds/>
      <CommandeSection/>
      <section className='w-full flex gap-2'>
        <div className='w-full p-4 flex items-center justify-center bg-white'><DoughnutChart   labels={['Produits','Categories']}
              dataSets={
                [{
                  data:[products.length, categories.length],
                  backgroundColor:['#0061B8','#0061B850']
                }
                ]
              }  /></div>
        <div className='w-full bg-white'><LinearChart dataSets={[{data:[10,3,100,800,400,250,125],backgroundColor:['#0061B8'],}]} labels={['january','feb','mach','april','may','june','july','august','september','october','november','december']}/></div>
      </section>
    </section>
  )
}

export default Dashboard