import React from 'react'
import { DelBtn, EditBtn, Header, Theader } from '../../components'
import { commandeHeader} from '../../constant'
import { Link } from 'react-router-dom'
import { useSnapshot } from 'valtio'
import { appStateProxy, deleteCategory } from '../../core/stores'

const Commandes = () => {
  const {orders}=useSnapshot(appStateProxy)
  return (
    <section className='w-full h-full flex flex-col gap-4'>
      <Header />
      <table className="min-w-full divide-y divide-gray-300 bg-white">
      <Theader labels={commandeHeader} />
      <tbody className="bg-white">
                {orders.map((order,idx) => (
                  <tr key={idx} className="even:bg-gray-50">
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{order.deliveryId}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{order.codePromo}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><span className='flex  gap-4'>
                      <EditBtn id={order.id} setId={''} setDisplay={''}/>
                      <DelBtn  delteMethode={undefined} id={order.id}  />
                      </span></td>
                  </tr>
                ))}
              </tbody>
    </table>
    </section>
  )
}

export default Commandes