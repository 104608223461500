import React, { useState } from 'react'
import { DelBtn, EditBtn, Header, Theader } from '../../components'
import {userHeader} from '../../constant'
import { Link } from 'react-router-dom'
import { useSnapshot } from 'valtio'
import { appStateProxy, deleteCategory } from '../../core/stores'
import { deleteCustomer } from '../../core/stores/customer'

const Clients = () => {
  const [editCustomer, setEditCustomer] =useState<{id:string}>()
  const [modal,setModal]=useState<boolean>()
  const {customers}=useSnapshot(appStateProxy)
  return (
    <section className='w-full h-full flex flex-col gap-4'>
      <Header/>
      <table className="min-w-full divide-y divide-gray-300 bg-white">
      <Theader labels={userHeader} />
      <tbody className="bg-white">
                {customers.map((customer,idx) => (
                  <tr key={idx} className="even:bg-gray-50">
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{customer.firstName}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{customer.lastName}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">{customer.avatar&&<img className='w-10 border rounded-full' src={customer.avatar} alt={customer.lastName}/>}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><span className='flex  gap-4'>
                      <EditBtn id={customer.id} setId={setEditCustomer} setDisplay={setModal}/>
                      <DelBtn  delteMethode={deleteCustomer} id={customer.id}  />
                      </span></td>
                  </tr>
                ))}
              </tbody>
    </table>
    </section>
  )
}

export default Clients