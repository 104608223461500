import React, { useEffect, useState } from 'react'
import { Navbar, Sidebar } from '../components'
import { Outlet, useNavigate } from 'react-router-dom'
import { listenAuthStatus } from '../core/firebase'
import { appStateProxy } from '../core/stores'
import { useSnapshot } from 'valtio'

const DashboardLayout = () => {
  const navigate=useNavigate()
  const {setProfile, profile,init}=useSnapshot(appStateProxy)
  // appState.init()
  useEffect(()=>{
    listenAuthStatus((res)=>{
      !res?navigate('/'):onInit(res.uid)
    })
  },[])

  const onInit =(uid:string) => {
    setProfile(uid)
    init()
  }
  return (
    <section className='w-screen h-screen flex flex-col'>
        <div className='absolute w-full'><Navbar userEmail={profile?.email} /></div>
        <section className='w-full h-full flex pt-14'>
            <Sidebar/>
            <div className='w-full bg-gray-50 overflow-y-auto px-1'><Outlet/></div>
        </section>
    </section>
  )
}

export default DashboardLayout