import { PencilSquareIcon } from '@heroicons/react/24/outline'
import React from 'react'

type IEditBtn={
    id:string,
    setId:any
    setDisplay:any
}

const EditBtn = (props:IEditBtn) => {
    const clickHandler=() => {
        props.setId((prev:any)=>({...prev,id:props.id}))
        props.setDisplay((prev:any)=>!prev)
    }
  return (
    <span  onClick={clickHandler} className='w-6 cursor-pointer'><PencilSquareIcon  /></span>
  )
}

export default EditBtn