import { fsAdd, fsDelete, fsGet, fsListen, fsUpdate } from "../firebase";
import { IShop } from "../models";

const colName = "shops"

const getShops = async (): Promise<IShop[]> => {
    const data: any[] = await fsGet(colName)
    return data;
}
const listenShops=(cb: (shops:IShop[])=>void):void=>{
    fsListen(colName,cb)
}
const addShop = async (data: Partial<IShop>): Promise<string> => {
    const { id } = await fsAdd(colName, data)
    return id
}

const updateShop = async (id: string, data: Partial<IShop>): Promise<void> => {
    await fsUpdate(colName, id, data)
}

const deleteShop = async (id: string): Promise<void> => {
    await fsDelete(colName, id)
}

export { getShops, addShop, updateShop, deleteShop,listenShops} 