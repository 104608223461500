import React from 'react'
type IModal={
    children:any
}
const Modal = (props:IModal) => {
  return (
    <section className='fixed z-50 left-0 right-0 top-0 w-screen h-screen bg-[#00000020] flex justify-center items-center'>
        {props.children}
    </section>
  )
}

export default Modal