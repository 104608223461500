import React from 'react'
import logo from '../assets/icons/adnoc.png'
import Dropdown from './DropDown'
import { logout } from '../core/firebase'

type INavbar={
  userEmail?: string
}
const Navbar = (props:INavbar) => {
  return (
    <div className='w-full p-2 bg-primary text-white flex justify-between '>
      {/* <div className='w-10 bg-contain bg-no-repeat  ' style={{backgroundImage:`url(${logo})`}}/> */}
      <div>logo</div>
      <div>
        {
          props.userEmail?<Dropdown
          label={props.userEmail}
          lists={[{label:'logout',cb:logout}]}
          />:''
        }
      </div>
    </div>
  )
}

export default Navbar