import { onAuthStateChanged, signInAnonymously, signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword } from "firebase/auth"
import { auth, fsSet } from "."

const listenAuthStatus = (cb:(res:any)=>any) => {
    onAuthStateChanged(auth, cb)
}

const loginAnonimously = () => {
    return signInAnonymously(auth)
}

const loginWithEmail = async ( email: string, password: string) => {
    return await signInWithEmailAndPassword(auth, email, password)
}

const registerWithEmail = async (email: string, password: string, firstName: string, lastName: string) => {
     const {user }  = await createUserWithEmailAndPassword(auth, email, password)
     return fsSet('users', user.uid, { email, firstName, lastName })
}

const logout = () => {
     signOut(auth)
}


export {
    registerWithEmail,
    listenAuthStatus,
    loginAnonimously,
    loginWithEmail,
    logout
}