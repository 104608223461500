import React, { useEffect, useState } from 'react'
import { Input, SpinnerLoader } from '../../components'
import { IProduct } from '../../core/models'
import { useSnapshot } from 'valtio'
import { addProduct, appStateProxy, updateProduct, uploadProductFile } from '../../core/stores'
import { uploadFile } from '../../core/firebase/upload'

const AddProduct = ({setDisplay,id,setId}:any) => {
  const {categories}=useSnapshot(appStateProxy)
  const [product,setProduct] = useState<Pick<IProduct,"categoryId"|"quantity"|"unitPrice"|"images"|"name">>({categoryId:categories[0].id,quantity:0,unitPrice:0,images:'',name:''})
  const [file,setFile]=useState<any>(null)
  const [loader,setLoader]=useState<boolean>()
  const {products}=useSnapshot(appStateProxy)
  const prod=products.find((prod:any)=>prod.id===id)
  console.log(id)

  const changeHandler =(e:any)=>setProduct((prev)=>({...prev,[e.target.name]:e.target.value}))
  const changeFileHandler =(e:any)=>setFile(()=>e.target.files[0])

  const cancelledHandler=()=>{
    setDisplay((prev:any)=>!prev)
    setId((prev:any)=>({...prev,id:''}))    
  }

  const submitHandler =async()=>{
    if(loader ||!product.categoryId||!product.name||!product.quantity||!product.unitPrice||!file)return
    try {
      setLoader((prev:any)=>!prev)
      if(!prod){
        const resId=await addProduct(product)
        const imagesPath=await uploadProductFile(resId,file)
        const data={...product,images:imagesPath}
        await updateProduct(resId,data)
      }
      if(id){
        let newFile=file
        if(file!==prod?.images){
          newFile=await uploadProductFile(id,file) 
        }
        const newProd={...product,images:newFile}
        await updateProduct(id,newProd)
      }
      setLoader((prev)=>!prev)
      setProduct(()=>({categoryId:categories[0].id,images:'',name:'',description:'',unitPrice:0,quantity:0}))
      setId((prev:any)=>({...prev,id:''}))
      setFile(()=>'')    
    } catch (error:any) {
      setLoader((prev:any)=>!prev)
    }
  }
  useEffect(()=>{
    setProduct((prev:any)=>({...prev,name:prod?.name?prod?.name:'',description:prod?.description?prod?.description:'',quantity:prod?.quantity?prod?.quantity:0,unitPrice:prod?.unitPrice?prod?.unitPrice:0,categoryId:prod?.categoryId?prod?.categoryId:categories[0].id}))
    id&& setFile(()=>prod?.images)
  },[])
  return (
    <section className='bg-white flex flex-col p-4 rounded-lg gap-3'>
    <span onClick={cancelledHandler} className='text-right m-3 cursor-pointer select-none'>annuler</span>
    <div className='w-full flex items-center gap-2  '>
      <label >Designation:</label>
      <Input
      IType='text'
      name='name'
      value={product.name}
      onChangeValue={changeHandler}
      />
    </div>
  
    <div className='flex gap-2'>
    <div className='flex items-center gap-2 '>
      <label>Quantiter:</label>
      <Input
      IType='number'
      name='quantity'
      value={product.quantity}
      onChangeValue={changeHandler}
      />
    </div>
    <div className='flex items-center gap-2 '>
      <label>Prix:</label>
      <Input
      IType='number'
      name='unitPrice'
      value={product.unitPrice}
      onChangeValue={changeHandler}
      />
    </div>
    </div>
    <div><select onChange={changeHandler}  className='w-full px-2 py-3 outline-none bg-white border' name="categoryId" >{categories.map((cat,idx)=>(<option key={idx} value={cat.id}>{cat.name}</option>))}</select></div>
    <div className='w-full border flex items-center justify-center py-4'>
        <input onChange={changeFileHandler} multiple type='file'/>
    </div>
    <div className='w-full flex justify-center items-center px-10 py-2'>
      <button onClick={submitHandler} className=' w-full rounded-lg bg-adnocBg py-2 text-xl text-white' >
        {loader?<SpinnerLoader/>:'Valider'}
      </button>
    </div>
  </section>
  )
}

export default AddProduct